<template>
  <main v-if="zone">
    <PageTitleContainer>
      <PageTitle>{{ zone.name }}</PageTitle>

      <template #actions>
        <router-link class="btn btn-outline-secondary" :to="{ name: 'suppliers.zones' }">Lista zone</router-link>
        <a v-if="zone.imageUrl" class="btn btn-outline-secondary ms-2" :href="zone.imageUrl" target="_blank">Open image</a>
      </template>
    </PageTitleContainer>

    <div>
      <p><strong>Nome</strong>: {{ zone.name }}</p>

      <div>
        <p><strong>Descrizione</strong></p>
        <div><!-- Todo: nl2br -->
          {{ zone.description }}
        </div>
      </div>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  $api.fetchZone(to.params.id)
    .then(res => next(vm => {
      vm.zone = res?.data;
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      zone: null,
    };
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
